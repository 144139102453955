import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';
const endpoint = 'https://admin.siddiqueenterprise.com/public/api/';
//const endpoint = 'https://admin.bdlawservice.com/public/api/';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
providedIn: 'root'
})

export class JwtService {
    constructor(private httpClient: HttpClient) { }

    login(username:string, password:string): Observable<any> {
        return this.httpClient.post<{access_token:  string}>(endpoint + 'login', {username, password}).pipe(tap(res => {
          localStorage.setItem('access_token', res.api_token);
          localStorage.setItem('loggedin_name', res.name);
          localStorage.setItem('loggedin_username', res.username);
          localStorage.setItem('loggedin_lawyercode', res.l_id);
          localStorage.setItem('loggedin_ad_user', res.ad_user);
        }))
    }

    logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('loggedin_name');
      localStorage.removeItem('loggedin_username');
      localStorage.removeItem('loggedin_lawyercode');
      localStorage.removeItem('loggedin_ad_user');
      localStorage.clear();
    }

}